import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React, { useRef, useState } from "react";
import { useStaticQuery, graphql /*, Link */ } from "gatsby";
import SectionHeader from "./sectionheader.js";
import Slider from "react-slick";

const Carousel = () => {
  const sliderRef = useRef(null);
  const [isBeginning, setIsBeginning] = useState(true);
  const [isEnd, setIsEnd] = useState(false);

  const data = useStaticQuery(graphql`
    {
      TopProducts: allMarkdownRemark(
        sort: { fields: [frontmatter___date_added], order: DESC }
        limit: 10
        filter: { frontmatter: { content: { in: ["product"] }, category: { eq: "gear" } } }
      ) {
        edges {
          node {
            frontmatter {
              title
              link
              image {
                childImageSharp {
                  fluid(maxWidth: 500) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3.1,
    slidesToScroll: 3.1,
    responsive: [
      {
        breakpoint: 576, // Mobile breakpoint
        settings: {
          slidesToShow: 1.5,
          slidesToScroll: 1.5,
        },
      },
    ],
    afterChange: (current) => {
      setIsBeginning(current === 0);
      // Adjust the "end" detection if needed
      setIsEnd(current >= data.TopProducts.edges.length - 3);
    },
  };

  return (
    <div className="carousel-container">

      <div
        style={{
          display: "flex",
          width: "100%",
          paddingBottom: "20px",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <p style={{ margin: 0 }}>
          <b>Gear</b>
        </p>
        <div className="carousel-arrows">
          <button
            style={{
              background: "none",
              border: "none",
              opacity: isBeginning ? 0 : 1,
              transition: "opacity 0.2s ease, box-shadow 0.2s ease, color 0.3s ease",
            }}
            className="arrow prev-arrow"
            onClick={() => sliderRef.current.slickPrev()}
            onMouseEnter={(e) => (e.currentTarget.style.color = "#7d4aa3")}
            onMouseLeave={(e) => (e.currentTarget.style.color = "")}
          >
            ←
          </button>

          <button
            style={{
              background: "none",
              border: "none",
              transition: "color 0.3s ease",
            }}
            className="arrow next-arrow"
            onClick={() => sliderRef.current.slickNext()}
            disabled={isEnd}
            onMouseEnter={(e) => (e.currentTarget.style.color = "#7d4aa3")}
            onMouseLeave={(e) => (e.currentTarget.style.color = "")}
          >
            →
          </button>
        </div>
      </div>

      <Slider ref={sliderRef} {...settings}>
        {data.TopProducts.edges.map(({ node }, index) => (
          <div key={index} className="carousel-slide" style={{ marginBottom: "0" }}>
<div
  className="carousel-item"
  style={{
    height: "250px",
    paddingRight: "30px",
    position: "relative",
    // REMOVE overflow: hidden and borderRadius from here
  }}
  onMouseEnter={(e) => {
    // Hover changes for text
    const p = e.currentTarget.querySelector("p");
    p.style.color = "purple";
    p.style.whiteSpace = "normal";
    // Scale the image
    const img = e.currentTarget.querySelector("img");
    img.style.transform = "scale(1.1)";
  }}
  onMouseLeave={(e) => {
    const p = e.currentTarget.querySelector("p");
    p.style.color = "";
    p.style.whiteSpace = "nowrap";
    const img = e.currentTarget.querySelector("img");
    img.style.transform = "scale(1)";
  }}
>
  {/* Outer link (if external). If it's internal, consider using gatsby Link */}
  <a
    href={node.frontmatter.link}
    target="_blank"
    rel="noopener noreferrer"
    style={{ textDecoration: "none", color: "inherit" }}
  >
    {/* 
      1) Add a new wrapper that matches the image size (150x150).
      2) Give it borderRadius and overflow: hidden so the corners stay rounded.
    */}
    <div
      className="image-wrapper"
      style={{
        width: "150px",
        height: "150px",
        borderRadius: "10px",
        overflow: "hidden",
      }}
    >
      <img
        style={{
          width: "100%",       // fill the wrapper
          height: "100%",
          objectFit: "cover",
          transition: "transform 0.3s ease",
          // borderRadius here isn't strictly needed if the wrapper has it.
        }}
        src={node.frontmatter.image.childImageSharp.fluid.src}
        alt={node.frontmatter.title}
      />
    </div>

    <p
      style={{
        fontSize: "14px",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        transition: "color 0.3s ease, whiteSpace 0.3s ease",
      }}
    >
      {node.frontmatter.title}
    </p>
  </a>
</div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Carousel;