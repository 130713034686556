import React, { useRef, useState } from "react";
import SectionHeader from "../elements/sectionheader.js";
import ProductsHome from "../elements/products_home.js";
import ProductsGear from "../elements/products_gear.js";
import ProductsCoffee from "../elements/products_coffee.js";
import ProductsFun from "../elements/products_fun.js";

const Products = () => {
 
  return (
    <div className="carousel-container" css = {{ borderRadius:`20px`, '@media (max-width: 750px)':{marginLeft:`5%`, marginRight:`5%`}}}>
      <div className="carousel-header">
        <SectionHeader text="Brett's Favorite Things ✨" />
      </div>
    <div style={{paddingLeft:`10%`, paddingRight:`10%`, border:`#7d4aa3 5px solid`, borderRadius:`20px`, paddingTop:`2rem`, marginBottom:`2rem`,background:`white`}}>
    <ProductsHome />
    <ProductsGear />
    <ProductsCoffee />
    <ProductsFun />
    </div>
    </div>
  );
};

export default Products;