import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Layout from "../components/layout"
import Hero from "../components/new_hero"
import Products from "../components/products.js"
import Blog from "../components/blog"
import Footer from "../components/footer"
import { Helmet } from "react-helmet"
import EmailSignup from '../components/emailsignup.js'
import Projects from "../components/projects"
import Updates from "../components/updates"
import Announcement from "../components/announcement"

export default () =>
<Layout>
<StaticQuery
  query={graphql`
    query {
      fileName: file(relativePath: { eq: "img/homepage/homepage_preview.png" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `}
  render={data => (
    <Helmet title="Brett Bejcek">
  <meta name="description" content="Data Science + Design + Community: I live by my top values of zest, curiosity and creativity. At work, I develop predictive models, encode meaning into visusalizations, and run our experimentation platform. Outside of work, I am an avid journaler, a side project connoisseur, and a coffee chat enthusiast. All thoughts are my own and do not represent clients or employers." />
  <meta name="image" content={data.fileName.childImageSharp.fluid.src} />
  <meta property="og:type" content="website" />
  <meta property="og:title" content="Brett Bejcek" />
  <meta property="og:description" content="Data Science + Design + Community: I live by my top values of zest, curiosity and creativity. At work, I develop predictive models, encode meaning into visusalizations, and run our experimentation platform. Outside of work, I am an avid journaler, a side project connoisseur, and a coffee chat enthusiast. All thoughts are my own and do not represent clients or employers." />
  <meta property="og:image" content={data.fileName.childImageSharp.fluid.src} />
  <meta name="twitter:card" content="summary" />
  <meta name="twitter:title" content="Brett Bejcek" />
  <meta name="twitter:description" content="Data Science + Design + Community: I live by my top values of zest, curiosity and creativity. At work, I develop predictive models, encode meaning into visusalizations, and run our experimentation platform. Outside of work, I am an avid journaler, a side project connoisseur, and a coffee chat enthusiast. All thoughts are my own and do not represent clients or employers." />
  <meta name="twitter:image" content={data.fileName.childImageSharp.fluid.src} />
    </Helmet>
  )}
/>
<Announcement/>
<Hero/>
<Projects/>
<Products/>
<EmailSignup/>
<Footer/>
</Layout>
